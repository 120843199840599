import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Order } from "@/core/model/Orders";
import ToastService from "@/core/services/ToastService";

export interface OrderList {
  page: number;
  limit: number;
  total: number;
  items: Array<Order>;
}
export interface OrderInfo {
  order: Order;
  list: OrderList;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class OrdersModule extends VuexModule implements OrderInfo {
  order = {} as Order;
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;
  errors = [];

  @Mutation
  [Mutations.ORDER_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.ORDER_UPDATE_DATA](data) {
    this.order = data;
  }

  @Mutation
  [Mutations.ORDER_CREATE](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Action
  [Actions.ORDER_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ error }) => {
          //const errors = response.data && response.data.errors ? response.data.errors : (response.data && response.data.message ? [response.data.message] : []);
          console.log("ERROR1", error);
          //this.context.commit(Mutations.SET_ERROR, errors);
          reject(error);
        });
    });
  }

  @Action
  [Actions.ORDER_GET](uuid) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.get(resource, uuid)
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ error }) => {
          console.log("ERROR1", error);
          reject(error);
        });
    });
  }

  @Action
  [Actions.ORDER_CREATE](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/loan-requests";
      ApiService.setHeader();
      ApiService.post(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.ORDER_CREATE, true);
          this.context.commit(Mutations.ORDER_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.ORDER_CREATE, false);
          reject(response);
        });
    });
  }

  @Action
  async [Actions.ORDER_UPDATE_STATE](params) {
    try {
      const resource = `/loan-requests/${params.order}/update-status`;
      ApiService.setHeader();
      const response = await ApiService.put(resource, params);
      const data = response.data;
      //this.context.commit(Mutations.UPDATE_STATE_SUCCESS, data);
      console.log('Se ejecutó la API correctamente');
      ToastService.show(`Order actualizada con éxito`,"success");
    } catch (error) {
      console.log('No se ejecutó la API correctamente:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        const errorMessages = Object.values(errors) as string[]; // Aserción de tipo
        // Mostrar los mensajes de error utilizando ToastService
        errorMessages.forEach(errorMessage => {
          ToastService.show(errorMessage, 'error');
        });
      } else {
        throw error;
      }
    }
  }
}
