class CommonModel {
  private _created_at = "";
  private _updated_at = "";
  private _active = false;

  get created_at(): string {
    return this.formatDateTime(this._created_at);
  }

  set created_at(value: string) {
    this._created_at = value;
  }

  get updated_at(): string {
    return this.formatDateTime(this._updated_at);
  }

  set updated_at(value: string) {
    this._updated_at = value;
  }

  formatDateTime(dateString: string) {
    const date = new Date(dateString);
    return typeof date !== "undefined" ? date.toLocaleString("es-ES") : "";
  }

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  getActiveFormatted() {
    return `<span class="badge badge-${this._active ? "success" : "warning"}">${
      this._active ? "Activo" : "Inactivo"
    }</span>`;
  }
}

export { CommonModel };
