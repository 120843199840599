import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Category } from "@/core/model/Category";

export interface CategoryList {
  page: number;
  limit: number;
  total: number;
  items: Array<Category>;
}
export interface CategoryInfo {
  list: CategoryList;
  success: boolean;
}

@Module
export default class CategoryModule
  extends VuexModule
  implements CategoryInfo
{
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;

  @Mutation
  [Mutations.CATEGORY_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.CATEGORY_SUCCESS](response) {
    this.success = response;
  }

  @Action({rawError: true})
  [Actions.CATEGORY_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/catalog/categories";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.CATEGORY_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.CATEGORY_SUCCESS, false);
          reject(response);
        });
    });
  }
}
