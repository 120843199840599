import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutual } from "@/core/model/Mutual";
import {ISite, Site} from "@/core/model/Site";

interface ICatalog {
  brands: Array<string>;
  categories: Array<Array<string>>;
}

export interface MutualList {
  page: number;
  limit: number;
  total: number;
  items: Array<Mutual>;
}
export interface MutualInfo {
  site: Site;
  mutual: Mutual;
  catalog: ICatalog;
  success: boolean;
  errors: Array<string>;
}

@Module
export default class MutualesModule extends VuexModule implements MutualInfo {
  mutual = {} as Mutual;
  catalog = {} as ICatalog;
  site = {} as Site;
  mutualSettings = [];
  success = false;
  errors = [];

  @Mutation
  [Mutations.MUTUAL_UPDATE_DATA](data) {
    this.mutual = data;
  }

  @Mutation
  [Mutations.MUTUAL_SUCCESS](response) {
    this.success = response;
  }

  @Mutation
  [Mutations.MUTUAL_SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.MUTUAL_UPDATE_SETTINGS](data) {
    this.mutualSettings = data;
  }

  @Mutation
  [Mutations.SITE_UPDATE_DATA](data) {
    if(typeof data.uuid !== "undefined"){
      this.site = new Site(data);
    }
  }

  @Mutation
  [Mutations.MUTUAL_UPDATE_CATALOG](data) {
    this.catalog = data;
  }

  @Action
  [Actions.MUTUAL_GET]() {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/profile";
      ApiService.setHeader();
      ApiService.query(resource, {})
        .then(({ data }) => {
          this.context.commit(Mutations.MUTUAL_UPDATE_DATA, data);
          resolve(data);
        })
        .catch(({ error }) => {
          console.log("ERROR1", error);
          reject(error);
        });
    });
  }

  @Action
  [Actions.MUTUAL_GET_SETTINGS]() {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/settings";
      ApiService.setHeader();
      ApiService.query(resource, {})
        .then(({ data }) => {
          this.context.commit(Mutations.MUTUAL_UPDATE_SETTINGS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action
  [Actions.MUTUAL_SAVE_SETTINGS](data) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/settings";
      ApiService.setHeader();
      ApiService.put(resource, data)
        .then(({ data }) => {
          this.context.commit(Mutations.MUTUAL_SUCCESS, true);
          this.context.commit(Mutations.MUTUAL_UPDATE_SETTINGS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SITE_GET]() {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/site";
      ApiService.setHeader();
      ApiService.query(resource, {})
          .then(({ data }) => {
            this.context.commit(Mutations.SITE_UPDATE_DATA, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors =
                response.data && response.data.errors
                    ? response.data.errors
                    : response.data && response.data.message
                        ? [response.data.message]
                        : [];
            this.context.commit(Mutations.SET_ERROR, errors);
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.MUTUAL_GET_CATALOG]() {
    return new Promise<void>((resolve, reject) => {
      const resource = "/mutual/catalog";
      ApiService.setHeader();
      ApiService.query(resource, {})
          .then(({ data }) => {
            this.context.commit(Mutations.MUTUAL_UPDATE_CATALOG, data);
            resolve(data);
          })
          .catch(({ response }) => {
            const errors =
                response.data && response.data.errors
                    ? response.data.errors
                    : response.data && response.data.message
                        ? [response.data.message]
                        : [];
            this.context.commit(Mutations.SET_ERROR, errors);
            this.context.commit(Mutations.MUTUAL_SUCCESS, false);
            reject(response);
          });
    });
  }

}
