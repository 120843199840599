enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  PAYMENT_PLANS_GET = "paymentPlansGetAction",
  PAYMENT_PLANS_LIST = "paymentPlansListAction",
  PAYMENT_PLANS_CREATE = "paymentPlansCreateAction",
  PAYMENT_PLANS_UPDATE = "paymentPlansUpdateAction",

  ORDER_LIST = "listOrders",
  ORDER_GET = "getOrder",
  ORDER_CREATE = "saveOrder",
  ORDER_UPDATE_STATE = "updateStateOrder",

  MUTUAL_GET = "getMutual",
  MUTUAL_GET_SETTINGS = "getMutualSettings",
  MUTUAL_SAVE_SETTINGS = "saveMutualSettings",
  MUTUAL_GET_CATALOG = "getMutualCatalog",
  SITE_GET = "getSite",

  USERS_GET = "usersGetAction",
  USERS_LIST = "usersListAction",
  USERS_CREATE = "usersCreateAction",
  USERS_UPDATE = "usersUpdateAction",

  PRODUCT_LIST = "listProducts",
  TAX_TERM_LIST = "listTaxTerms",

  CATEGORY_LIST = "categoryListAction",
  BRAND_LIST = "brandListAction",
  CATALOG_UPDATE = "catalogUpdateAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  PAYMENT_PLANS_UPDATE_LIST = "paymentPlansUpdateListMutation",
  PAYMENT_PLANS_SET_ERROR = "paymentPlansSetErrorMutation",
  PAYMENT_PLANS_SUCCESS = "paymentPlansSuccessMutation",
  PAYMENT_PLANS_UPDATE_ONE = "paymentPlansUpdateOneMutation",

  ORDER_UPDATE_LIST = "OrderUpdateListData",
  ORDER_UPDATE_DATA = "OrderUpdateData",
  ORDER_CREATE = "OrderCreate",
  ORDER_STATE_UPDATE = "OrderStateUpdate",

  MUTUAL_UPDATE_DATA = "mutualUpdateData",
  MUTUAL_UPDATE_SETTINGS = "mutualUpdateSettings",
  MUTUAL_UPDATE_CATALOG = "mutualUpdateCatalog",
  MUTUAL_SUCCESS = "mutualSuccess",
  MUTUAL_SET_ERROR = "mutualSetError",

  SITE_UPDATE_DATA = "siteUpdateData",

  USERS_UPDATE_LIST = "usersUpdateListMutation",
  USERS_SET_ERROR = "usersSetErrorMutation",
  USERS_SUCCESS = "usersSuccessMutation",
  USERS_UPDATE_ONE = "usersUpdateOneMutation",

  PRODUCT_UPDATE_LIST = "productUpdateListData",

  TAX_TERM_UPDATE_LIST = "taxtermsUpdateListData",
  TAX_TERM_SUCCESS = "taxTermsSuccess",

  CATEGORY_UPDATE_LIST = "categoryUpdateListMutation",
  CATEGORY_SUCCESS = "categorySuccessMutation",
  BRAND_UPDATE_LIST = "brandUpdateListMutation",
  BRAND_SUCCESS = "brandySuccessMutation",
}

export { Actions, Mutations };
