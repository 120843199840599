import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PaymentPlansModule from "@/store/modules/PaymentPlansModule";
import OrdersModule from "@/store/modules/OrdersModule";
import MutualesModule from "@/store/modules/MutualesModule";
import UsersModule from "@/store/modules/UsersModule";
import ProductsModule from "@/store/modules/ProductsModule";
import TaxonomyTermModule from "@/store/modules/TaxonomyTermModule";
import CategoryModule from "@/store/modules/CategoryModule";
import BrandModule from "@/store/modules/BrandModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    PaymentPlansModule,
    OrdersModule,
    MutualesModule,
    UsersModule,
    ProductsModule,
    TaxonomyTermModule,
    CategoryModule,
    BrandModule,
  },
});

export default store;
