interface IUser {
  uuid: string;
  created_at: string;
  modified_at: string;
  status: boolean;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  username: string;
  roles: string;
}

class User implements IUser {
  status = false;
  created_at = "";
  email = "";
  firstname = "";
  lastname = "";
  modified_at = "";
  phone = "";
  username = "";
  uuid = "";
  roles = "";

  constructor(user: IUser) {
    this.load(user);
  }

  load(user: IUser) {
    this.status = user.status;
    this.created_at = user.created_at;
    this.email = user.email;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.modified_at = user.modified_at;
    this.phone = user.phone;
    this.username = user.username;
    this.uuid = user.uuid;
    this.roles = user.roles;
  }

  getFullName() {
    return this.firstname + " " + this.lastname;
  }

  getStatusName() {
    return this.status ? "Activo" : "Inactivo";
  }

  getStatusClass() {
    return this.status ? "success" : "warning";
  }

  getRoleName() {
    let role = "";

    switch (this.roles) {
      case "ROLE_ADMIN":
        role = "Administrador";
        break;
      case "ROLE_ADMIN_MUTUAL":
        role = "Admin Mutual";
        break;
      case "ROLE_USER_MUTUAL":
        role = "Empleado";
        break;
    }

    return role;
  }
}
export { IUser, User };
