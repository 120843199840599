import { ElMessage } from "element-plus";

/**
 * @description service to call Toast notifications
 */
class ToastService {
  public static show(message: string, type) {
    ElMessage({
      message: message,
      type: type,
      showClose: false,
      dangerouslyUseHTMLString: true,
    });
  }
}

export default ToastService;
