import {User} from "@/core/model/User";

interface ISite {
  uuid: string;
  name: string;
  domain: string;
  protocol: string;
  physical_uri: string;
  active: boolean;
  api_key: string;
  created_at: string;
  updated_at: string;
  created_by: User;
  updated_by: User;
}

class Site implements ISite{
  active = false;
  api_key = '';
  domain = '';
  name = '';
  physical_uri = '';
  protocol = '';
  uuid = '';
  created_at = '';
  created_by = {} as User;
  updated_at = '';
  updated_by = {} as User;

  constructor(site: ISite) {
    this.load(site);
  }

  load(site: ISite) {
    this.active = site.active;
    this.api_key = site.api_key;
    this.domain = site.domain;
    this.name = site.name;
    this.physical_uri = site.physical_uri;
    this.protocol = site.protocol;
    this.uuid = site.uuid;
    this.created_at = site.created_at;
    this.updated_at = site.updated_at;
    this.created_by = new User(site.created_by);
    this.updated_by = new User(site.updated_by);
  }

  getUrl() {
    return this.protocol + '://' + this.domain + (this.physical_uri != '' ? '/' + this.physical_uri : '');
  }
}


export { ISite, Site };
