import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { TaxonomyTerm } from "@/core/model/taxonomyTerm";

export interface TaxonomyTermList {
  page: number;
  limit: number;
  total: number;
  items: Array<TaxonomyTerm>;
}
export interface TaxonomyTermInfo {
  list: TaxonomyTermList;
  success: boolean;
}

@Module
export default class TaxonomyTermModule
  extends VuexModule
  implements TaxonomyTermInfo
{
  list = {
    page: 0,
    limit: 20,
    total: 0,
    items: [],
  };
  success = false;

  @Mutation
  [Mutations.TAX_TERM_UPDATE_LIST](list) {
    this.list = list;
  }

  @Mutation
  [Mutations.TAX_TERM_SUCCESS](response) {
    this.success = response;
  }

  @Action({rawError: true})
  [Actions.TAX_TERM_LIST](params) {
    return new Promise<void>((resolve, reject) => {
      const resource = "/taxonomy-terms";
      ApiService.setHeader();
      ApiService.query(resource, { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.TAX_TERM_UPDATE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          this.context.commit(Mutations.MUTUAL_SUCCESS, false);
          reject(response);
        });
    });
  }
}
