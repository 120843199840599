import ApiService from "@/core/services/ApiService";
import JwtService, { decodeToken } from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ToastService from "@/core/services/ToastService";

export interface User {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  roles: string;
  password: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    const decoded = decodeToken() as User;
    return {
      firstname: decoded.firstname,
      lastname: decoded.lastname,
      email: decoded.email,
      username: decoded.username,
      roles: decoded.roles,
      password: "",
    } as User;

    //return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.errors = [];
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  /*@Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login_check", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }*/

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login_check", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(( { response } ) => {
          const errors =
            response.data && response.data.errors
              ? response.data.errors
              : response.data && response.data.message
              ? [response.data.message]
              : [];
          this.context.commit(Mutations.SET_ERROR, errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({rawError: true})
  [Actions.VERIFY_AUTH](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("users/verify-token", payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject(response);
          });
    });
  }
}
