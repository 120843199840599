import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/payment-plans",
        name: "paymentplans",
        component: () => import("@/views/payment-plans/PaymentPansList.vue"),
      },
      {
        path: "/payment-plans/new",
        name: "paymentplans_new",
        component: () => import("@/views/payment-plans/PaymentPlanForm.vue"),
      },
      {
        path: "/payment-plans/:uuid",
        name: "paymentplans_edit",
        component: () => import("@/views/payment-plans/PaymentPlanForm.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/UsersList.vue"),
      },
      {
        path: "/users/new",
        name: "users_new",
        component: () => import("@/views/users/UsersForm.vue"),
      },
      {
        path: "/users/:uuid",
        name: "users_edit",
        component: () => import("@/views/users/UsersForm.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/orders/OrderList.vue"),
      },
      {
        path: "/orders/:uuid/details",
        name: "orders_detail",
        component: () => import("@/views/orders/OrderDetail.vue"),
      },
      {
        path: "/mutual/profile",
        name: "mutual-profile",
        component: () => import("@/views/mutual/MutualProfile.vue"),
      },
      {
        path: "/mutual/settings",
        name: "mutual-settings",
        component: () => import("@/views/mutual/MutualSettings.vue"),
      },
      {
        path: "/mutual/catalog",
        name: "mutual-catalog",
        component: () => import("@/views/mutual/MutualCatalog.vue"),
      },
      {
        path: "/mutual/site",
        name: "mutual-site",
        component: () => import("@/views/mutual/MutualSite.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/errors/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (JwtService.getToken()) {
    store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })
        .then((data) => {
          //console.log('thenn', data);
        })
        .catch((response) => {
          store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
          console.log('catch', response);
        });

  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
